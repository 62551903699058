(function(){
    'use strict';

    window.onload = function(){
        const body = document.body;
        const href = window.location.href;
        const pagina = href.split('/').pop();

        let id_google_analytics;
        if (document.querySelectorAll('#id-google_analytics').length > 0) {
            id_google_analytics = document.querySelector('#id-google_analytics').value;
            document.querySelector('#id-google_analytics').remove();
        }

        let d = new Date();
        let year = d.getFullYear();
        let month = d.getMonth();
        let day = d.getDate();
        let hour = d.getHours();
        let min = d.getMinutes();
        let sec = d.getSeconds();
        let cDate = new Date(year+1, month, day, hour, min, sec);
        let expiresdate = cDate;
                //Cookies management
                const EUcookies = leerCookie('EUcookies');

                if (pagina != 'cookies.php') {
                    if (id_google_analytics) {
                        let EUcookiesValues = EUcookies.split("");
                        if (EUcookiesValues[0] != 1) {
                            popUpAlert('', '', false, false);
                            let embedContent = document.querySelector('.embedContent');
                            document.querySelector('.close').remove();
                            embedContent.innerHTML = '<img src="images/cookies-vconnect.png" class="mb-3"  style="width:150px;"><h4>Configuración de cookies</h4><p class="pt-3 text-left" style="text-align: left;">Esta web solo instalará cookies analíticas y de publicidad comportamental si usted lo acepta expresamente. <ul style="text-align:left"><li class="ml-5">Las cookies _ga son propiedad de Google Analytics y sirven para mejorar nuestros servicios mediante el análisis de sus hábitos de navegación.</li><li class="ml-5">Las cookies Doubleclick son propiedad de Google Inc. y sirven para mostrarle publicidad relacionada con sus preferencias en base a un perfil elaborado a partir de sus hábitos de navegación (por ejemplo, páginas visitadas).</li></ul></p><p style="text-align:left;">Podrá revocar este consentimiento, obtener más información e informarse de sus derechos en la <a target="_blank" href="/cookies.php">Política de cookies</a>.</p><div class="row justify-content-end" style="width:100%"><button class="btn secondary" data-action="configurar-cookies">Configurar Cookies</button><button class="btn primary ml-2" data-action="acept-cookies">Aceptar cookies</button></div>';
                            document.querySelector('[data-action="acept-cookies"]').addEventListener('click', () => {
                                
                                document.cookie = "EUcookies=11;secure ;SameSite=None;expires=" + expiresdate.toUTCString();
                                document.querySelector('.popupin').classList.remove('popupin');
                                body.style.overflow = 'auto';
                                setTimeout(() => {
                                    document.querySelector('#velo').remove()
                                }, 300)
                                let googleScript = document.createElement('div');
                                googleScript.innerHTML = '<script async src="https://www.googletagmanager.com/gtag/js?id=' + id_google_analytics + '"></script>';
                                document.querySelector('footer').append(googleScript);
        
                                window.dataLayer = window.dataLayer || [];
        
                                function gtag() {
                                    dataLayer.push(arguments);
                                }
                                gtag('js', new Date());
                                gtag('config', id_google_analytics);
        
                            })
                            document.querySelector('[data-action="configurar-cookies"]').addEventListener('click', () => {
                                let row1 = '<h5>Cookies técnicas y funcionales</h5><div class="row"><div class="col-8"><p style="text-align: left;">Necesarias para el uso de la web. Guardan el inicio de sesión del usuario.</p></div><div class="col-4"><div class="form-check"><input type="checkbox" checked disabled value="1" id="cookies-tecnicas"><label class="ml-3" for="cookies-tecnicas">Permitir</label></div></div></div>'
                                let row2 = '<h5>Cookies de analíticas</h5><div class="row"><div class="col-8"><p style="text-align: left;">Nos permiten hacer un seguimiento de tu actividad en la plataforma para mejorar la experiencia de usuario.</p></div><div class="col-4"><div class="form-check"><input type="checkbox" value="1" id="cookies-analiticas"><label class="ml-3" for="cookies-tecnicas">Permitir</label></div></div></div>'
                                let row3 = '<div class="row justify-content-end" style="width:100%"><button class="btn secondary" data-action="acept-cookies">Aceptar todas</button><button class="btn primary ml-2" data-action="save-cookies">Guardar configuración</button></div>';
                                embedContent.innerHTML = '<img src="images/cookies-vconnect.png" class="mb-3"  style="width:256px;"><h4>Configuración de cookies</h4><p class="pt-3 text-left" style="text-align: left;">Este es el configurador avanzado de cookies propias y de terceros. Aquí puedes modificar parámetros que afectarán directamente a tu experiencia en esta web.</p>' + row1 + row2 + row3;
                                document.querySelector('[data-action="acept-cookies"]').addEventListener('click', () => {
                                    let expiresdate = cDate;
                                    document.cookie = "EUcookies=11 ;secure; SameSite=None; expires=" + expiresdate.toUTCString();
                                    document.querySelector('.popupin').classList.remove('popupin');
                                    body.style.overflow = 'auto';
                                    setTimeout(() => {
                                        document.querySelector('#velo').remove()
                                    }, 300)
                                    let googleScript = document.createElement('div');
                                    googleScript.innerHTML = '<script async src="https://www.googletagmanager.com/gtag/js?id=' + id_google_analytics + '"></script>';
                                    document.querySelector('footer').append(googleScript);
        
                                    window.dataLayer = window.dataLayer || [];
        
                                    function gtag() {
                                        dataLayer.push(arguments);
                                    }
                                    gtag('js', new Date());
                                    gtag('config', id_google_analytics);
        
                                })
                                document.querySelector('[data-action="save-cookies"]').addEventListener('click', () => {
                                    let expiresdate = cDate;
                                    let cookieAnalitica = document.querySelector('#cookies-analiticas').checked ? '1' : '0';
                                    document.cookie = "EUcookies=1" + cookieAnalitica + ";SameSite=None;secure ;expires=" + expiresdate.toUTCString();
                                    document.querySelector('.popupin').classList.remove('popupin');
                                    body.style.overflow = 'auto';
                                    setTimeout(() => {
                                        document.querySelector('#velo').remove()
                                    }, 300)
                                    if (cookieAnalitica == 1) {
        
                                        let googleScript = document.createElement('div');
                                        googleScript.innerHTML = '<script async src="https://www.googletagmanager.com/gtag/js?id=' + id_google_analytics + '"></script>';
                                        document.querySelector('footer').append(googleScript);
        
                                        window.dataLayer = window.dataLayer || [];
        
                                        function gtag() {
                                            dataLayer.push(arguments);
                                        }
                                        gtag('js', new Date());
                                        gtag('config', id_google_analytics);
        
                                    }
                                })
                            })
                        }
        
                    } else if (!id_google_analytics) {
                        if (EUcookies != 1) {
                            popUpAlert('', '', false, false);
                            let embedContent = document.querySelector('.embedContent');
                            document.querySelector('.close').remove();
                            embedContent.innerHTML = '<img src="images/cookies-vconnect.png" class="mb-3"  style="width:150px;"><p class="pt-3">We use technical cookies that allow the user to browse our website and enable its functions or services, thus allowing the operation and functioning of the website. For more information you can visit our <a target="_blank" href="/cookies.php">Cookies Policy</a>.</p><div class="row justify-content-end"><button class="btn primary" data-action="acept-cookies">Accept cookies</button></div>';
                            document.querySelector('[data-action="acept-cookies"]').addEventListener('click', () => {
                                let expiresdate = cDate;
                                document.cookie = "EUcookies=1; secure; SameSite=None; expires=" + expiresdate.toUTCString();
                                document.querySelector('.popupin').classList.remove('popupin');
                                body.style.overflow = 'auto';
                                setTimeout(() => {
                                    document.querySelector('#velo').remove()
                                }, 300)
                            })
                        }
                    }
                }
        
    }
})();